import React, { useState, useEffect, useMemo } from 'react';
import "./App.css"


function Stars() {
  const stars = useMemo(() => {
    return Array.from({ length: 50 }, (_, i) => {
      const style = {
        left: `${Math.floor(Math.random() * 100)}vw`,
        top: `${Math.floor(Math.random() * 100)}vh`,
        transform: `scale(${Math.random()})`,
        opacity: Math.random(),
        animationDelay: `${Math.random()}s`
      };
      return <div key={i} className="star" style={style}></div>;
    });
  }, []);

  return (
    <div className="stars">
      {stars}
    </div>
  );
}

const TimePassed = () => {
  const [timePassed, setTimePassed] = useState<any>(undefined);
  const [isNight, setIsNight] = useState(false);

  useEffect(() => {
    const calculateTimePassed = () => {
      const targetDate = Date.UTC(2023, 6, 23, 8, 0);
      const currentDate: any = new Date();
      const timePassed = currentDate - targetDate;
      setTimePassed(timePassed);

      // Check if it's night time (between 6PM and 6AM)
      const currentHour = currentDate.getUTCHours();
      setIsNight(currentHour < 6 || currentHour >= 18);
    }

    calculateTimePassed();
    const interval = setInterval(calculateTimePassed, 500);

    return () => {
      clearInterval(interval);
    }
  }, []);

  if (timePassed < 0) {
    return <p>It's not yet 23rd July 2023 11:30AM</p>
  }
  const secondsPassed = Math.floor(timePassed / 1000);
  const minutesPassed = Math.floor(secondsPassed / 60);
  const hoursPassed = Math.floor(minutesPassed / 60);
  const daysPassed = Math.floor(hoursPassed / 24);

  return (
    <div className={`center ${isNight ? 'night' : ''}`}>
      {isNight && <Stars />}
      <div className="time-passed">
        <p>
          <p>Days</p>
          {daysPassed}
        </p>
        <p>
          <p>Hours</p>
          {hoursPassed % 24}
        </p>
        <p>
          <p>Minutes</p>
          {minutesPassed % 60}
        </p>
        <p>
          <p>Seconds</p>
          {secondsPassed % 60}
        </p>
      </div>
      <div className="letter">
        <p>My beloved,</p>
        <p>Each second that passes, brings me closer to the day we meet again.</p>
        <p>With all my love,</p>
        <p>Mehdi</p>
      </div>
    </div>
  );
}

export default TimePassed;
